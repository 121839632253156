.Impress {
  background-color: #ff00d5;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  width: calc(100% - 450px);
  padding: 1rem 2rem;

  transform: translateX(100%);
  transition: transform 1s ease-in-out;

  @media screen and (max-width: 950px) {
    width: 100vw;
  }

  &--visible {
    transform: translateX(0%);
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  @media screen and (max-width: 430px) {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.75rem;
    }
  }

  button {
    all: unset;

    position: absolute;
    top: 1rem;
    right: 1rem;

    font-family: "BebasNeueRegular", sans-serif;
    font-size: 1.25rem;
    color: #fff;
    cursor: pointer;
  }
}
