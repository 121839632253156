.Footer {
  position: absolute;
  z-index: 1;
  bottom: 0.5rem;
  right: 1rem;

  button {
    all: unset;
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 1.25rem;
    color: #7300ff;
    cursor: pointer;
  }
}
