@import "webfonts.css";

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: #7300ff;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  white-space: pre-line;
}

pre {
  white-space: break-spaces;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "BebasNeueRegular", sans-serif;
  text-transform: capitalize;
  margin: 0;
  line-height: 1;
  font-weight: normal;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 3.5rem;
}

a {
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
