.Header {
  padding: 1rem;

  &__Subline {
    display: inline-block;
    text-align: right;
  }

  @media screen and (max-width: 435px) {
    h1 {
      font-size: clamp(4.5rem, 20vw, 6rem);
    }

    h2 {
      font-size: clamp(2rem, 12vw, 3.5rem);
    }
  }
}
